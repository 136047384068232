export const ROUTER = {
    HOME_PAGE_ROUTE: "/",
    SERVICE_PAGE_ROUTE: "/service",
    COURSES_PAGE_ROUTE: "/courses",
    WORK_PAGE_ROUTE: "/work",
    CONTACT_PAGE_ROUTE: "/contact",
    AUTH_PAGE_ROUTE: "/auth",
    AUTH_PAGE_ITEM_ROUTE: "/auth_item/:id",
    BLOG_PAGE_ROUTE: "/blog",
    BLOG_ITEM_PAGE_ROUTE: "/blog-item/:id",
    FACULTET_PAGE_ROUTE: "/facultet",
    SERVICE_INFO_PAGE_ROUTE: "/service-info", 
    TREANING_PAGE_ROUTE: "/training/:id",
    FACULTET_TREANING_PAGE_ROUTE: "facultet/training/:id",
    ERROR_PAGE: "*"
}